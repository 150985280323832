import { Component, Vue } from 'vue-property-decorator';

@Component
export default class VueCalMixin extends Vue {
  activeView = 'week';

  created () {
    if (window.innerWidth < 768)
      this.activeView = 'day';
  }
}
