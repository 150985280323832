import Vue from 'vue';
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { BatchHttpLink } from 'apollo-link-batch-http';
import VueApollo from 'vue-apollo';

Vue.use(VueApollo);

const httpLink = new BatchHttpLink({
  uri: (window as any).GRAPHQL_ENDPOINT,
  credentials: 'same-origin',
}) as unknown as ApolloLink;

const authLink = new ApolloLink((operation, next) => {
  const csrfToken = document.querySelector('meta[name=csrf-token]').getAttribute('content');
  operation.setContext(context => ({
    ...context,
    headers: {
      ...context.headers,
      'X-CSRF-Token': csrfToken,
    },
  }));

  return next(operation);
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache(),
});

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
