/**
 * Get rgb values as number
 * @param hexColor Hex color
 */
const getRgbFromHex = (hexColor: string) => {
  // Strip the leading #
  let color = hexColor.replace(/^#/, '');

  // Convert to 3 char codes
  // e.g. `E0F` --> `EE00FF`
  if (color.length === 3) color = color.replace(/(.)/g, '$1$1');

  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);

  return { r, g, b };
};

const getTextColorFromRgb = (r: number, g: number, b: number) => (r * 0.299 + g * 0.587 + b * 0.114 > 150 ? 'black' : 'white');

/**
 * Get best contrast text color (black/white)
 * @param hexColor Hex color
 */
export const getTextColorFromHex = (hexColor: string) => {
  if (!hexColor)
    return null;

  const { r, g, b } = getRgbFromHex(hexColor);
  return getTextColorFromRgb(r, g, b);
};
