<template>
  <div>
    <div class="d-flex justify-content-between">
      <div>
        <h6>Välj {{ subject }}</h6>
        <p v-if="show" class="d-block mt-n2">
          Klicka för att välja en eller flera lokaler.
        </p>
      </div>
      <div>
        <button class="btn btn-sm mb-3" aria-controls="" :class="{ 'btn-primary': !show, 'btn-dark': show }"
          @click="show = !show">
          <template v-if="!show">
            Visa
          </template>
          <template v-else>
            Dölj
          </template>
          filter
        </button>
      </div>
    </div>
    <div v-if="show" class="mb-4">
      <button v-for="item in items" :id="item.value" :key="item.value" class="btn btn-sm mr-2 mb-2" :class="{
        'btn-primary': selectedItems.includes(item.value),
        'btn-secondary': !selectedItems.includes(item.value),
      }" @click="() => toggleItem(item.value)" @contextmenu.prevent="() => toggleItem(item.value, true)">
        {{ item.label }}
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Model, Prop, Vue } from 'vue-property-decorator';

interface Item {
  value: string;
  label: string;
}

@Component
export default class Toggler extends Vue {
  @Prop({ type: Array, required: true })
  items!: Item[];

  @Model('select', { type: Array })
  selectedItems!: string[];

  @Prop(String)
  subject!: string;

  show = true;

  created() {
    if (this.selectedItems.length === 0)
      this.init();
  }

  init() {
    // this.$emit('select', this.items.map(i => i.value));
  }

  toggleItem(id: string, isolated = false) {
    if (isolated)
      if (this.selectedItems.length === 1 && this.selectedItems.includes(id))
        this.init();
      else
        this.$emit('select', [id]);
    else
      if (this.selectedItems.includes(id))
        // Filter out
        this.$emit('select', this.selectedItems.filter(v => v !== id));
      else
        // Filter in
        this.$emit('select', [...this.selectedItems, id]);
  }
}
</script>
