import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
};


export type OpasAct = {
  __typename?: 'OpasAct';
  bass?: Maybe<Scalars['String']>;
  bassoon?: Maybe<Scalars['String']>;
  bassoonText?: Maybe<Scalars['String']>;
  cello?: Maybe<Scalars['String']>;
  clarinet?: Maybe<Scalars['String']>;
  clarinetText?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  durationText?: Maybe<Scalars['String']>;
  event: OpasEvent;
  extra?: Maybe<Scalars['String']>;
  extraText?: Maybe<Scalars['String']>;
  flute?: Maybe<Scalars['String']>;
  fluteText?: Maybe<Scalars['String']>;
  harp?: Maybe<Scalars['String']>;
  harpText?: Maybe<Scalars['String']>;
  horn?: Maybe<Scalars['String']>;
  hornText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instrumentation?: Maybe<Scalars['String']>;
  keyboard?: Maybe<Scalars['String']>;
  keyboardText?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  oboe?: Maybe<Scalars['String']>;
  oboeText?: Maybe<Scalars['String']>;
  percussion?: Maybe<Scalars['String']>;
  percussionText?: Maybe<Scalars['String']>;
  soloists: Array<OpasSoloist>;
  stringsText?: Maybe<Scalars['String']>;
  timpani?: Maybe<Scalars['String']>;
  timpaniText?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  trombone?: Maybe<Scalars['String']>;
  tromboneText?: Maybe<Scalars['String']>;
  trumpet?: Maybe<Scalars['String']>;
  trumpetText?: Maybe<Scalars['String']>;
  tuba?: Maybe<Scalars['String']>;
  tubaText?: Maybe<Scalars['String']>;
  viola?: Maybe<Scalars['String']>;
  violin1?: Maybe<Scalars['String']>;
  violin2?: Maybe<Scalars['String']>;
  vocals?: Maybe<Scalars['String']>;
  vocalsText?: Maybe<Scalars['String']>;
};

export type OpasAddress = {
  __typename?: 'OpasAddress';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type OpasAddressFunction = {
  __typename?: 'OpasAddressFunction';
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type OpasAddressGroup = {
  __typename?: 'OpasAddressGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  participants: Array<OpasParticipant>;
};

export type OpasDress = {
  __typename?: 'OpasDress';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type OpasDuty = {
  __typename?: 'OpasDuty';
  artist?: Maybe<OpasAddress>;
  dutyType?: Maybe<OpasDutyType>;
  event: OpasEvent;
  id: Scalars['ID'];
  instrument?: Maybe<OpasInstrument>;
  seat?: Maybe<Scalars['String']>;
};

export type OpasDutyType = {
  __typename?: 'OpasDutyType';
  duties: Array<OpasDuty>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type OpasEvent = {
  __typename?: 'OpasEvent';
  acts: Array<OpasAct>;
  artistGroups: Array<OpasAddressGroup>;
  conductor?: Maybe<OpasAddress>;
  dress?: Maybe<OpasDress>;
  duties: Array<OpasDuty>;
  dutyTypes: Array<OpasDutyType>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  eventType: OpasEventType;
  id: Scalars['ID'];
  instruments: Array<OpasInstrument>;
  location?: Maybe<OpasAddress>;
  maxInstrumentation?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  orchestra?: Maybe<OpasAddress>;
  participants: Array<OpasParticipant>;
  project?: Maybe<OpasProject>;
  soloists: Array<OpasSoloist>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  week: Scalars['String'];
};

export type OpasEventType = {
  __typename?: 'OpasEventType';
  color?: Maybe<Scalars['String']>;
  eventTypeGroup: OpasEventTypeGroup;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type OpasEventTypeGroup = {
  __typename?: 'OpasEventTypeGroup';
  eventTypes: Array<OpasEventType>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type OpasInstrument = {
  __typename?: 'OpasInstrument';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type OpasParticipant = {
  __typename?: 'OpasParticipant';
  artist: OpasAddress;
  artistGroup: OpasAddressGroup;
  event: OpasEvent;
  function?: Maybe<OpasAddressFunction>;
  id: Scalars['ID'];
  instrument?: Maybe<OpasInstrument>;
};

export type OpasProject = {
  __typename?: 'OpasProject';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type OpasSoloist = {
  __typename?: 'OpasSoloist';
  act?: Maybe<OpasAct>;
  artist: OpasAddress;
  id: Scalars['ID'];
  instrument?: Maybe<OpasInstrument>;
};

export type Query = {
  __typename?: 'Query';
  opasEvent?: Maybe<OpasEvent>;
  opasEventTypeGroups: Array<OpasEventTypeGroup>;
  opasEvents: Array<OpasEvent>;
  opasLastUpdated: Scalars['String'];
  timewiseBookings: Array<TimewiseBooking>;
  timewiseLastUpdated: Scalars['String'];
  timewiseVenues: Array<TimewiseVenue>;
};


export type QueryOpasEventArgs = {
  id: Scalars['ID'];
};


export type QueryOpasEventsArgs = {
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  eventTypes?: Maybe<Array<Scalars['ID']>>;
};


export type QueryTimewiseBookingsArgs = {
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  venues?: Maybe<Array<Scalars['ID']>>;
};

/** Activity of Timewise booking (ÖT) */
export type TimewiseActivity = {
  __typename?: 'TimewiseActivity';
  color?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Booking from Timewise (ÖT) */
export type TimewiseBooking = {
  __typename?: 'TimewiseBooking';
  activity?: Maybe<TimewiseActivity>;
  canceled: Scalars['Boolean'];
  endTime?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  production?: Maybe<TimewiseProduction>;
  startTime?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  venue?: Maybe<TimewiseVenue>;
};

/** Production of Timewise booking (ÖT) */
export type TimewiseProduction = {
  __typename?: 'TimewiseProduction';
  color?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  statusColor?: Maybe<Scalars['String']>;
};

/** Venue of Timewise booking (ÖT) */
export type TimewiseVenue = {
  __typename?: 'TimewiseVenue';
  color?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type OpasEventQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OpasEventQuery = (
  { __typename?: 'Query' }
  & { opasEvent?: Maybe<(
    { __typename?: 'OpasEvent' }
    & Pick<OpasEvent, 'id' | 'startDate' | 'endDate' | 'maxInstrumentation' | 'notes' | 'week'>
    & { eventType: (
      { __typename?: 'OpasEventType' }
      & Pick<OpasEventType, 'id' | 'name'>
      & { eventTypeGroup: (
        { __typename?: 'OpasEventTypeGroup' }
        & Pick<OpasEventTypeGroup, 'id' | 'name'>
      ) }
    ), acts: Array<(
      { __typename?: 'OpasAct' }
      & Pick<OpasAct, 'id' | 'title' | 'durationText' | 'instrumentation'>
    )>, project?: Maybe<(
      { __typename?: 'OpasProject' }
      & Pick<OpasProject, 'id' | 'name'>
    )>, orchestra?: Maybe<(
      { __typename?: 'OpasAddress' }
      & Pick<OpasAddress, 'id' | 'name'>
    )>, conductor?: Maybe<(
      { __typename?: 'OpasAddress' }
      & Pick<OpasAddress, 'id' | 'name'>
    )>, dress?: Maybe<(
      { __typename?: 'OpasDress' }
      & Pick<OpasDress, 'id' | 'name'>
    )>, instruments: Array<(
      { __typename?: 'OpasInstrument' }
      & Pick<OpasInstrument, 'id' | 'name'>
    )>, duties: Array<(
      { __typename?: 'OpasDuty' }
      & Pick<OpasDuty, 'id'>
      & { artist?: Maybe<(
        { __typename?: 'OpasAddress' }
        & Pick<OpasAddress, 'id' | 'name'>
      )>, instrument?: Maybe<(
        { __typename?: 'OpasInstrument' }
        & Pick<OpasInstrument, 'id'>
      )> }
    )>, participants: Array<(
      { __typename?: 'OpasParticipant' }
      & Pick<OpasParticipant, 'id'>
      & { artist: (
        { __typename?: 'OpasAddress' }
        & Pick<OpasAddress, 'id' | 'name'>
      ), instrument?: Maybe<(
        { __typename?: 'OpasInstrument' }
        & Pick<OpasInstrument, 'id' | 'name'>
      )>, function?: Maybe<(
        { __typename?: 'OpasAddressFunction' }
        & Pick<OpasAddressFunction, 'id' | 'name' | 'code'>
      )> }
    )>, soloists: Array<(
      { __typename?: 'OpasSoloist' }
      & Pick<OpasSoloist, 'id'>
      & { artist: (
        { __typename?: 'OpasAddress' }
        & Pick<OpasAddress, 'id' | 'name'>
      ), instrument?: Maybe<(
        { __typename?: 'OpasInstrument' }
        & Pick<OpasInstrument, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type OpasEventsQueryVariables = Exact<{
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
}>;


export type OpasEventsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'opasLastUpdated'>
  & { opasEvents: Array<(
    { __typename?: 'OpasEvent' }
    & Pick<OpasEvent, 'id' | 'startDate' | 'endDate'>
    & { eventType: (
      { __typename?: 'OpasEventType' }
      & Pick<OpasEventType, 'id' | 'name' | 'color'>
      & { eventTypeGroup: (
        { __typename?: 'OpasEventTypeGroup' }
        & Pick<OpasEventTypeGroup, 'id' | 'name'>
      ) }
    ), project?: Maybe<(
      { __typename?: 'OpasProject' }
      & Pick<OpasProject, 'id' | 'name'>
    )>, location?: Maybe<(
      { __typename?: 'OpasAddress' }
      & Pick<OpasAddress, 'id' | 'name'>
    )>, conductor?: Maybe<(
      { __typename?: 'OpasAddress' }
      & Pick<OpasAddress, 'id' | 'name'>
    )>, orchestra?: Maybe<(
      { __typename?: 'OpasAddress' }
      & Pick<OpasAddress, 'id' | 'name'>
    )> }
  )> }
);

export type TimewiseBookingsQueryVariables = Exact<{
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
}>;


export type TimewiseBookingsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'timewiseLastUpdated'>
  & { timewiseVenues: Array<(
    { __typename?: 'TimewiseVenue' }
    & { value: TimewiseVenue['id'], label: TimewiseVenue['name'] }
  )>, timewiseBookings: Array<(
    { __typename?: 'TimewiseBooking' }
    & Pick<TimewiseBooking, 'id' | 'startTime' | 'endTime' | 'subject' | 'canceled'>
    & { production?: Maybe<(
      { __typename?: 'TimewiseProduction' }
      & Pick<TimewiseProduction, 'id' | 'name' | 'color'>
    )>, activity?: Maybe<(
      { __typename?: 'TimewiseActivity' }
      & Pick<TimewiseActivity, 'id' | 'name' | 'color'>
    )>, venue?: Maybe<(
      { __typename?: 'TimewiseVenue' }
      & Pick<TimewiseVenue, 'id' | 'name'>
    )> }
  )> }
);


export const OpasEventQuery = gql`
    query opasEvent($id: ID!) {
  opasEvent(id: $id) {
    id
    startDate
    endDate
    maxInstrumentation
    notes
    week
    eventType {
      id
      name
      eventTypeGroup {
        id
        name
      }
    }
    acts {
      id
      title
      durationText
      instrumentation
    }
    project {
      id
      name
    }
    orchestra {
      id
      name
    }
    conductor {
      id
      name
    }
    dress {
      id
      name
    }
    instruments {
      id
      name
    }
    duties {
      id
      artist {
        id
        name
      }
      instrument {
        id
      }
    }
    participants {
      id
      artist {
        id
        name
      }
      instrument {
        id
        name
      }
      function {
        id
        name
        code
      }
    }
    soloists {
      id
      artist {
        id
        name
      }
      instrument {
        id
        name
      }
    }
  }
}
    `;
export const OpasEventsQuery = gql`
    query opasEvents($from: String, $to: String) {
  opasLastUpdated
  opasEvents(from: $from, to: $to) {
    id
    startDate
    endDate
    eventType {
      id
      name
      color
      eventTypeGroup {
        id
        name
      }
    }
    project {
      id
      name
    }
    location {
      id
      name
    }
    conductor {
      id
      name
    }
    orchestra {
      id
      name
    }
  }
}
    `;
export const TimewiseBookingsQuery = gql`
    query timewiseBookings($from: String, $to: String) {
  timewiseLastUpdated
  timewiseVenues {
    value: id
    label: name
  }
  timewiseBookings(from: $from, to: $to) {
    id
    startTime
    endTime
    subject
    canceled
    production {
      id
      name
      color
    }
    activity {
      id
      name
      color
    }
    venue {
      id
      name
    }
  }
}
    `;